<template>
  <b-container class="classic-form-page">
    <div class="center">
      <p>
        ❗️❗️The page is currently under maintenance. We apologize for any
        inconvenience caused. The page should be available soon.
      </p>

    </div>
  </b-container>
</template>

<script>
export default {
  name: 'NotAvailable',
};
</script>

<style scoped>
.classic-form-page {
  background-color: white;
  align-items: center;
  justify-content: center;
  color: #c71717;
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 10px 10px 10px;
  padding: 20px;
}
</style>
